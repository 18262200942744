














import { Component, Vue } from 'vue-property-decorator';
import { IContentPage } from '@/models/contentful';
import { contentfulService } from '@/services';
import Loader from '@/components/common/loader.vue';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import PageNotFound from '@/components/page-not-found.vue';
@Component({
  components: { PageNotFound, Loader },
})
export default class ContentPage extends Vue {
  private data: IContentPage | null = null;
  private loading = true;

  private async mounted() {
    if (this.$route.params.slug) {
      this.data = await contentfulService.fetchContentPageBySlug(this.$route.params.slug);
    }

    this.loading = false;
  }

  public get contentHtml() {
    if (this.data?.fields.content) {
      return documentToHtmlString(this.data.fields.content);
    }
    return null;
  }
}
