






























































import { Component } from 'vue-property-decorator';
import Loader from '@/components/common/loader.vue';
import { ApiDonationHistoryRecord, SocialSubscriptionStatus } from '@/models/api';
import { socialService } from '@/services';
import HistoryRecord from '@/components/profile-page/history-record.vue';
import { Action, Getter } from 'vuex-class';
import { Charity, Profile } from '@/models/store';
import { formatToCurrency } from '@/utils/string-utils';
import { auth } from '@/firebase-app';
import AuthMixin from '@/pages/auth/auth-mixin';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { formatDate } from '@/utils/date-utils';

@Component({
  components: {
    Loader,
    HistoryRecord,
  },
})
export default class ProfilePage extends AuthMixin {
  @Getter private profile!: Profile;
  @Getter private subscriptionStatus!: string | null;
  @Action private signOut!: () => void;
  @Getter private currentAmount!: number;
  @Getter private nextPaymentDate!: string;
  @Getter private currentCharities!: Charity[];
  private SocialSubscriptionStatus = SocialSubscriptionStatus;

  private history: ApiDonationHistoryRecord[] | null = null;
  private formatToCurrency = formatToCurrency;

  async created() {
    this.history = await socialService.getDonationHistory();
  }

  private async cancel() {
    if (!confirm('Weet je zeker dat je de donatie wilt stopzetten?')) {
      return;
    }

    await socialService.cancelSubscription();
    await this.updateUserProfile();

    this.$gtag.event('cancel_subscription');
  }

  private async changeAmount() {
    await this.$store.commit('clearSubscriptionActions');
    await this.$router.push({ name: 'ConfirmPage' });
  }

  private async updateUserProfile() {
    const profile = await socialService.getUserProfile();
    if (profile) {
      await this.$store.dispatch('handleProfileResponse', profile);
    }
  }

  private get nextPayment() {
    return format(new Date(this.nextPaymentDate), 'PPPP', {
      locale: nl,
    });
  }

  private get charityCopy() {
    return this.currentCharities?.length === 1 ? 'goed doel' : 'goede doelen';
  }

  private get totalShares() {
    return this.currentCharities?.reduce((t, c) => (t += c.shares), 0);
  }

  private get formattedCurrentAmount() {
    return formatToCurrency(this.currentAmount);
  }

  private get hasSubscription() {
    return this.subscriptionStatus === SocialSubscriptionStatus.Active;
  }

  private get formatSubscriptionStatus() {
    switch (this.subscriptionStatus) {
      case SocialSubscriptionStatus.Active:
        return 'actief';
      case SocialSubscriptionStatus.Canceled:
        return 'geannuleerd';
      case SocialSubscriptionStatus.Error:
        return 'defect';
      case SocialSubscriptionStatus.None:
        return 'inactief';
      default:
        return undefined;
    }
  }

  private get hasSubscriptionError() {
    return this.subscriptionStatus === SocialSubscriptionStatus.Error;
  }

  private get canCancelSubscription() {
    // Subscriptions that have errored can't be restarted. Therefore we don't want them
    // to be canceled because that would reveal the restart button. Users should
    // go through the checkout flow to fix their account - the sub will be canceled and
    // a new mandate will be created.
    // Also, canceled subs can't be re-cancelled
    return this.subscriptionStatus === SocialSubscriptionStatus.Active;
  }

  private async signUserOut() {
    try {
      await auth.signOut();
      await this.signOut();
      return this.$router.push({ name: 'CharityOverviewPage' });
    } catch (err) {
      console.error('Error while signing out', err);
    }
  }
}
