













import { Component, Vue } from 'vue-property-decorator';
import ProfileForm from '@/components/profile-page/profile-form.vue';
@Component({
  components: { ProfileForm },
})
export default class MyDataPage extends Vue {
}
