













import { Component, Prop, Vue } from 'vue-property-decorator';
import { ApiDonationHistoryRecord } from '../../models/api';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { formatToCurrency } from '@/utils/string-utils';

@Component({})
export default class HistoryRecord extends Vue {
  @Prop() readonly record!: ApiDonationHistoryRecord;

  private get charities() {
    return this.record.charities;
  }

  private getDonationAmount(value: string, shares: number) {
    const totalDonation = this.record.donation.value;
    const totalShares = this.record.charities.reduce((total, charity) => total + charity.shares, 0);
    const ratio = shares / totalShares;
    return formatToCurrency(ratio * Number(totalDonation));
  }

  private get charityCopy() {
    return this.record.charities.length === 1 ? 'goed doel' : 'goede doelen';
  }

  private get formattedDate() {
    // @ts-ignore
    return format(new Date(this.record.paymentCreatedDate), 'PPPP', {
      locale: nl,
    });
  }

  private get formattedTotal() {
    return formatToCurrency(this.record.donation.value);
  }
}
