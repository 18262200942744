

























import { Component, Vue } from 'vue-property-decorator';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

@Component({
  components: {
    LottieAnimation,
  },
})
export default class LoginSendPage extends Vue {}
