





























import { Component, Vue } from 'vue-property-decorator';
import { auth, getUser } from '@/firebase-app';
import firebase from 'firebase/app';

@Component
export default class LoginPage extends Vue {
  private email: string | null = null;
  private redirectTo = 'LoginPage';

  private created() {
    const user = getUser();
    if (this.$route.query?.redirectTo && !Array.isArray(this.$route.query?.redirectTo.length)) {
      this.redirectTo = this.$route.query.redirectTo as string;
    }

    const redirectToPageName = this.redirectTo === 'confirm' ? 'ConfirmPage' : 'ProfilePage';
    if (user) {
      this.$router.push({ name: redirectToPageName });
    }
  }

  private mounted() {
    const input = this.$refs.emailInput as HTMLInputElement;
    if (input) {
      input.focus();
    }
  }

  private async submit() {
    // validate email
    if (!this.email) {
      return;
    }

    const actionCodeSettings = {
      url: `${window.config.baseUrl}/auth?redirectTo=${encodeURIComponent(this.redirectTo)}&email=${encodeURIComponent(this.email)}`,
      handleCodeInApp: true,
    };

    // Try and sign in
    auth
      .sendSignInLinkToEmail(this.email, actionCodeSettings)
      .then(() => {
        if (!this.email) {
          return;
        }

        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        return this.$router.push({ name: 'LoginSendPage' });
      })
      .catch((error: firebase.auth.Error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`Error while sending sign in link: ${errorMessage} (${errorCode})`);
      });
  }
}
