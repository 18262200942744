




import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import { auth } from '@/firebase-app';

@Component
export default class Auth extends Vue {
  private async created() {
    const url = location.href;
    const email = this.$route.query?.email;
    const redirectPageName =
      this.$route.query?.redirectTo === 'confirm' ? 'ConfirmPage' : 'ProfilePage';

    if (auth.currentUser) {
      return this.$router.push({ name: redirectPageName });
    }

    if (!firebase.auth().isSignInWithEmailLink(url)) {
      return;
    }

    try {
      // Try and sign in
      await firebase.auth().signInWithEmailLink(`${email}`, url);

      // Continue to out homepage
      return this.$router.push({ name: redirectPageName });
    } catch (err) {
      console.error(`Error while signing in: ${err.message}`);
      return this.$router.push({ name: 'LoginPage' });
    }
  }
}
