























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { socialService } from '@/services';
import { formatDate } from '@/utils/date-utils';
import { Profile } from '@/models/store';
import { addYears, isValid, parse } from 'date-fns';
import { nl } from 'date-fns/locale';
import { Getter, Mutation } from 'vuex-class';
import AuthMixin from '@/pages/auth/auth-mixin';

@Component({})
export default class ProfileForm extends AuthMixin {
  @Getter private profile!: Profile;
  @Mutation private setProfile!: (payload: Profile) => void;
  @Prop({ default: false }) private showBack!: boolean;
  private errorState: string | null = null;

  private userProfile: Profile = {
    firstName: '',
    lastName: '',
    dob: '',
    nationality: '',
  };

  private bd = {
    valid: false,
    above1900: false,
    isOfLegalAge: false,
    error: null as string | null,
  };

  private mounted() {
    const el = this.$refs.nameInput as HTMLInputElement;
    if (el) {
      el.focus();
    }
  }

  private async submit() {
    this.errorState = null;
    if (!this.isComplete) {
      return;
    }

    try {
      await socialService.setUserProfile(
        this.userProfile.firstName || '',
        this.userProfile.lastName || '',
        formatDate(this.userProfile.dob, 'yyyy-MM-dd') || '',
        this.userProfile.nationality || ''
      );

      // Update the state
      this.setProfile(this.userProfile);

      // Redirect
      let redirectTo = 'ProfilePage';
      if (this.$route.query?.redirectTo && !Array.isArray(this.$route.query?.redirectTo.length)) {
        redirectTo = this.$route.query.redirectTo as string;
      }
      await this.$router.push({ name: redirectTo });
    } catch (err) {
      console.log('Error while setUserProfile', err);
      this.errorState = 'Er ging iets mis. Controleer de velden en probeer het nog een keer.';
    }
  }

  private get isComplete() {
    return (
      this.userProfile.firstName &&
      this.userProfile.lastName &&
      this.userProfile.nationality &&
      this.bdOke
    );
  }

  private get bdOke() {
    return this.bd.valid && this.bd.above1900 && this.bd.isOfLegalAge;
  }

  @Watch('profile', { immediate: true })
  private handleProfileChange() {
    this.userProfile = { ...this.profile };
    if (this.userProfile.dob) {
      this.userProfile.dob = formatDate(this.userProfile.dob, 'dd-MM-yyyy');
    }
    this.handleBirthDateChange();
  }

  @Watch('userProfile.dob', { immediate: true })
  private handleBirthDateChange() {
    const legalAge = 13;
    const checkFromDate = new Date(1900, 0, 1);
    const maxDate = addYears(new Date(), legalAge * -1);

    if (!this.userProfile.dob) {
      this.bd.valid = false;
      return;
    }

    const parsedDate = parse(this.userProfile.dob, 'dd-MM-yyyy', new Date(), { locale: nl });
    this.bd.valid = isValid(parsedDate);
    this.bd.above1900 = parsedDate > checkFromDate;
    this.bd.isOfLegalAge = parsedDate < maxDate;

    if (this.bd.valid && this.bd.above1900 && !this.bd.isOfLegalAge) {
      this.bd.error = `Je moet tenminste ${legalAge} jaar oud zijn om gebruik te maken van Soc1al.`;
    } else {
      this.bd.error = null;
    }
  }
}
