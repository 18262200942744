



























import { Component, Vue } from 'vue-property-decorator';
import Loader from '@/components/common/loader.vue';
import { socialService } from '@/services';
import ContentPage from '@/pages/global/content.vue';

@Component({
  components: {
    Loader,
    ContentPage,
  },
})
export default class AdminPage extends Vue {
  private loading = true;
  private admins: string[] | null = null;

  private async created() {
    try {
      this.admins = await socialService.adminGetAdmins();
    } catch (err) {
      // show content page, thus 404
    } finally {
      this.loading = false;
    }
  }

  private get authenticated() {
    return !!this.admins;
  }

  private async getExport() {
    const data = await socialService.adminGetExport();
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
  }
}
